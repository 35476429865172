import { LatLngExpression } from 'leaflet';
import { InjectionKey, Ref } from 'vue';
import { useBookingCard, useBookingFormCard } from './features/bookings/composables';

interface AppProvide {
    currentTime: Ref<Date>;
    setDrawerFullHeight: (fullHeight: boolean) => void;
}

interface SearchPageProvide {
    setHeaderVisibility: (value: boolean) => void;
    setHeaderAppendVisibility: (value: boolean) => void;
    setPage: (value: number) => void;
}

interface MapUnderlayProvide {
    flyTo(latLng: LatLngExpression, zoom?: number): void;
}

export const AppProvideKey: InjectionKey<AppProvide> = Symbol.for('travelplanner:app');
export const SearchPageProvideKey: InjectionKey<SearchPageProvide> = Symbol.for('travelplanner:searchWrapper');
export const MapUnderlayProvideKey: InjectionKey<MapUnderlayProvide> = Symbol.for('travelplanner:mapUnderlay');
export const OnlineBookingIsAvailableKey: InjectionKey<Ref<boolean>> = Symbol.for('travelplanner:onlineBookingIsAvailable');
export const BookingCardProvideKey: InjectionKey<ReturnType<typeof useBookingCard>> = Symbol.for('travelplanner:bookingCard');
export const BookingFormCardProvideKey: InjectionKey<ReturnType<typeof useBookingFormCard>> = Symbol.for('travelplanner:bookingFormCard');
