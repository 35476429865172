<template>
    <error-boundary
        v-model="lastKnownError"
        :errors-to-display="['PlaceDetailsError', 'GeolocationPositionError', 'BookingError', 'ProfileError']"
        :stop-propagation="true"
        @reload="refresh"
        @error-captured="registerError"
    >
        <v-app class="tp-app" :class="cssClasses">
            <v-layout v-if="mainStore.ready" full-height>
                <v-dialog v-if="mainStore.isDevMode" v-model="dialogIsVisible" max-width="500">
                    <template #activator="{ props: activatorProps }">
                        <div class="position-absolute top-0 right-0 pa-2" style="z-index: 1000">
                            <v-btn v-bind="activatorProps" icon color="warning">
                                <v-icon icon="mdi-xml" size="32" />
                            </v-btn>
                        </div>
                    </template>
                    <dev-mode-configurator
                        v-model="mainStore.devModeConfiguration"
                        @update:model-value="dialogIsVisible = false"
                        @cancel="dialogIsVisible = false"
                        @reset="resetConfig"
                    />
                </v-dialog>

                <site-header
                    v-model:current-language="language"
                    :menu-items="menuItems"
                    :is-logged-in="userStore.isLoggedIn"
                    :profile-info="userStore.getProfile"
                    class="tp-app__header"
                />

                <app-drawer v-model="showAppDrawer" :full-height="drawerIsFullHeight" class="tp-app__drawer">
                    <div id="appDrawerPrepend" />
                    <router-view :key="route.path" name="appDrawer" />
                </app-drawer>

                <v-main>
                    <div class="tp-app__banner">
                        <feedback-banner v-if="!isMobile" />
                    </div>
                    <v-container fluid class="pa-0 h-100" style="position: relative">
                        <router-view name="main" />
                    </v-container>
                </v-main>
            </v-layout>

            <v-overlay :model-value="mainStore.isLoading" scrim="white" class="align-center justify-center">
                <v-progress-circular color="primary" indeterminate size="64" />
            </v-overlay>
            <silent-login v-if="config.silentLoginEnabled && userLoaded && !userStore.isLoggedIn" />

            <v-dialog v-model="showDevModeNotification" max-width="500" persistent>
                <v-card>
                    <v-card-title class="pt-4 px-6 pt-md-6">
                        <h1 class="text-h3">Developer Mode Activated</h1>
                    </v-card-title>
                    <v-card-text class="text-h6 font-weight-regular py-0">
                        <p class="my-2">Developer Mode allows you to change various settings that affect how this app functions.</p>
                        <p class="my-2">
                            All changes are persistent for the current session. To disable Developer Mode, simply open the app in a new tab.
                        </p>
                    </v-card-text>
                    <v-card-actions class="px-4 pb-4 px-md-6">
                        <v-spacer />
                        <v-btn color="primary" @click="showDevModeNotification = false">Understood</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-app>
    </error-boundary>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { getCurrentInstance, nextTick, onMounted, provide, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useMainStore } from '@/features/common/stores';
import { SiteHeader, ErrorBoundary } from '@geta/kolumbus-frontend/components';
import { AppDrawer, FeedbackBanner, DevModeConfigurator } from '@/features/common/components';
import { useDevModeActivator } from '@/features/common/composables';
import { useUserStore, SilentLogin } from '@geta/kolumbus-frontend/features/authentication';
import { AppProvideKey } from './injectionKeys';
import { createGtm } from 'vue-gtm';
import { useEventListener } from '@vueuse/core';
import { useBookingFeature } from './features/bookings/composables';
import { useDisplay } from 'vuetify';

useBookingFeature();
const route = useRoute();
const router = useRouter();
const mainStore = useMainStore();
const userStore = useUserStore();
const { menuItems, language, showAppDrawer, config, lastKnownError } = storeToRefs(mainStore);
const { mobile: isMobile } = useDisplay();
const { dialogIsVisible, showDevModeNotification, init: initDevModeActivator, resetConfig } = useDevModeActivator();

const userLoaded = ref(false);
const cssClasses = ref<string[]>([]);
const currentTime = ref(new Date());
const drawerIsFullHeight = ref(true);

function setDrawerFullHeight(fullHeight: boolean) {
    drawerIsFullHeight.value = fullHeight;
}

provide(AppProvideKey, { currentTime, setDrawerFullHeight });

watch(language, async (value, oldValue) => {
    if (value !== oldValue) {
        try {
            await router.replace({ params: { locale: value }, query: route.query });
        } catch (e) {
            mainStore.registerError(e);
            router.push('/');
        }
    }
});

const instance = getCurrentInstance();

onMounted(async () => {
    // start clock
    setInterval(() => (currentTime.value = new Date()), 1000);

    // init store
    await mainStore.init();
    initDevModeActivator();

    // add event listener to add/remove focused class whenever an input field is focused
    useEventListener(
        document,
        ['focus', 'blur'],
        e => {
            if ((e.target as Element).nodeName !== 'INPUT') return;

            if (e.type === 'focus') {
                cssClasses.value.push('tp-app--has-focused');
            } else if (e.type === 'blur') {
                cssClasses.value = cssClasses.value.filter(x => x !== 'tp-app--has-focused');
            }
        },
        true
    );

    // load user
    if (config.value?.silentLoginEnabled) {
        await userStore.loadUserSilent();
        await userStore.loadUser();
        await userStore.loadProfile(menuItems.value?.loginLink);

        userLoaded.value = true;
    }

    // set up gtm
    if (instance && config.value?.googleTagManagerConfig?.id) {
        instance.appContext.app.use(createGtm({ id: config.value.googleTagManagerConfig.id }));
    }

    mainStore.ready = true;
});

function refresh() {
    location.reload();
}

function registerError(e: Error | undefined) {
    mainStore.registerError(e);
}

// reset app drawer when going from desktop to mobile
watch(
    isMobile,
    value => {
        if (!value) return;

        nextTick(() => (showAppDrawer.value = true));
    },
    { immediate: true }
);
</script>

<style lang="scss">
.tp-app {
    &__header {
        z-index: 1000;
    }

    &__drawer {
        z-index: 900;
    }

    &__banner {
        position: absolute;
        right: 0;
        z-index: 1000;
        width: calc(100% - var(--v-layout-left));
    }
}

.v-main {
    z-index: 1;
}
</style>
