import { computed, nextTick, watch } from 'vue';
import { SearchMode, useMainStore } from '@/features/common/stores';
import { RouteLocationNormalized, useRouter } from 'vue-router';
import { routeNames } from '@/router';
import { useDeparturesSearchStore } from '@/features/departures/stores';
import { useTripSearchStore } from '@/features/trips/stores';
import { PlaceType } from '@/types/webapi';
import { i18nRoute } from '@/i18n';
import { storeToRefs } from 'pinia';

export function useSearchModeToggle() {
    const router = useRouter();
    const { currentRoute } = router;
    const mainStore = useMainStore();
    const tripStore = useTripSearchStore();
    const departuresStore = useDeparturesSearchStore();
    const { searchMode } = storeToRefs(mainStore);
    const disabled = computed(() => tripStore.isLoading || departuresStore.isLoading);

    function setMode(value: RouteLocationNormalized) {
        searchMode.value =
            value.name === routeNames.tripSearch
                ? SearchMode.Trip
                : value.name === routeNames.departuresSearch
                  ? SearchMode.Departures
                  : SearchMode.None;
    }

    // set initial mode based on current route
    setMode(currentRoute.value);

    watch(searchMode, (value, oldValue) => {
        if (value == oldValue) return;

        if (value == SearchMode.Trip) {
            // copy over quay as from value, if set
            const { quay } = departuresStore.searchRequest;

            // reset store
            departuresStore.$reset();

            nextTick(async () => {
                // reset store
                tripStore.$reset();

                // navigate
                await router.push(
                    i18nRoute({
                        name: routeNames.tripSearch,
                        query: {
                            fromId: quay ? quay.id : undefined
                        }
                    })
                );
            });
        } else if (value == SearchMode.Departures) {
            // copy over from as quay value, if set and is quay
            const { from } = tripStore.searchRequest;

            // reset store
            tripStore.$reset();

            nextTick(async () => {
                // reset store
                departuresStore.$reset();

                // navigate
                await router.push(
                    i18nRoute({
                        name: routeNames.departuresSearch,
                        query: {
                            fromId: from?.type === PlaceType.TransitStation ? from.id : undefined
                        }
                    })
                );
            });
        }
    });

    watch(currentRoute, (value, oldValue) => {
        if (value.name == oldValue?.name) return;

        setMode(value);
    });

    return {
        mode: searchMode,
        disabled
    };
}
