<template>
    <div class="trip-leg-list" :class="themeClasses">
        <slot />
    </div>
</template>

<script setup lang="ts">
// @ts-ignore
import { makeThemeProps, provideTheme } from 'vuetify/lib/composables/theme.mjs';

const props = defineProps({ ...makeThemeProps() });
const { themeClasses } = provideTheme(props);
</script>
