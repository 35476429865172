import { createI18n, type I18n } from 'vue-i18n';
import { nextTick } from 'vue';
import type { RouteLocationNormalized, NavigationGuardNext, RouteLocationRaw } from 'vue-router';
import { useMainStore } from '@/features/common/stores';
import { isEmpty, merge } from 'lodash-es';
import { useContainer } from '@/plugins/inversify';
import { IAxiosId } from '@/plugins/axios';
import type { AxiosInstance } from 'axios';
import { en, no } from 'vuetify/locale';
import { en as _en, no as _no } from '@geta/kolumbus-frontend/locale';

const defaultMessages: any = {
    en: {
        $vuetify: merge(en, _en)
    },
    no: {
        $vuetify: merge(no, _no)
    }
};

function setupI18n(options = { locale: 'no' }) {
    const i18n = createI18n({
        fallbackLocale: 'en',
        legacy: false,
        globalInjection: true,
        ...options
    });
    setI18nLanguage(i18n, options.locale);
    return i18n;
}

const i18n = setupI18n();
export default i18n;

function setI18nLanguage(i18n: I18n<{}, {}, {}, string, false>, locale: string) {
    i18n.global.locale.value = locale;
    document.querySelector('html')?.setAttribute('lang', locale);
}

async function loadLocaleMessages(i18n: I18n<{}, {}, {}, string, false>, locale: string) {
    const container = useContainer();
    const axios = container.get<AxiosInstance>(IAxiosId);
    const mainStore = useMainStore();

    try {
        // load locale messages with dynamic import
        const res = await axios.get(`/jsl10n/Kolumbus.TravelPlanner?camel=true&json=true&lang=${locale}`);
        const messages = res.data.kolumbus.travelPlanner;

        // set locale and locale message
        i18n.global.setLocaleMessage(locale, merge({}, defaultMessages[locale], messages));

        // additional messages
        if (locale === 'no') {
            i18n.global.mergeLocaleMessage('no', {
                trips: {
                    resources: {
                        transferNotice: {
                            travelGuaranteeAboutPageLinkUrl: new URL('/reisegaranti', mainStore.config.kolumbusWebsiteBaseUrl).toString()
                        }
                    }
                }
            });
        } else if (locale === 'en') {
            i18n.global.mergeLocaleMessage('en', {
                trips: {
                    resources: {
                        transferNotice: {
                            travelGuaranteeAboutPageLinkUrl: new URL(
                                '/travel-guarantee',
                                mainStore.config.kolumbusWebsiteBaseUrl
                            ).toString()
                        }
                    }
                }
            });
        }
    } catch (e) {
        mainStore.registerError(e);
    }

    return nextTick();
}

export async function routeMiddleware(to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
    const paramLocale = Array.isArray(to.params.locale) ? to.params.locale[0] : to.params.locale;
    const mainStore = useMainStore();

    // use locale if paramsLocale is not in SUPPORT_LOCALES
    if (mainStore.config.supportedLanguages.indexOf(paramLocale) == -1) {
        return next({ params: { locale: mainStore.language } });
    }

    // set language in mainStore
    mainStore.language = paramLocale;

    // load locale messages
    if (!i18n.global.availableLocales.includes(paramLocale) || isEmpty(i18n.global.getLocaleMessage(paramLocale))) {
        await loadLocaleMessages(i18n, paramLocale);
    }

    // set i18n language
    setI18nLanguage(i18n, paramLocale);

    return next();
}

export function i18nRoute(to: RouteLocationRaw): RouteLocationRaw {
    return merge(to, { params: { locale: i18n.global.locale.value } });
}
