<template>
    <v-navigation-drawer
        v-model="_modelValue"
        :location="isMobile ? 'bottom' : 'left'"
        :width="isMobile ? 0 : 480"
        color="white"
        elevation="4"
        border="0"
        :rounded="isMobile && !fullHeight ? 't-lg' : '0'"
        permanent
        class="app-drawer"
        :class="cssClasses"
        :style="mainStyles"
    >
        <div class="app-drawer__inner">
            <div class="app-drawer__content">
                <slot />
            </div>
            <div class="app-drawer__toggle d-none d-md-flex align-md-center">
                <v-btn width="30" min-width="30" height="54" rounded="s-0 e-lg" class="pa-0 ma-0" @click="_modelValue = !_modelValue">
                    <v-icon size="36" :icon="_modelValue ? 'mdi-menu-left' : 'mdi-menu-right'" />
                </v-btn>
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { useDisplay } from 'vuetify';
import { computed } from 'vue';
import { useLayout } from 'vuetify';

interface Props {
    modelValue: boolean;
    fullHeight?: boolean;
}

const props = withDefaults(defineProps<Props>(), { fullHeight: true });
const _modelValue = useVModel(props, 'modelValue');
const { mobile: isMobile } = useDisplay();
const cssClasses = computed(() => ({ 'app-drawer--full-height': props.fullHeight }));
const { mainStyles } = useLayout();
</script>

<style lang="scss">
@use '@geta/kolumbus-frontend/styles/vuetify' as *;
@use 'vuetify/lib/styles/tools' as *;

.app-drawer {
    &__inner,
    &__content {
        height: 100%;
    }

    &__toggle {
        pointer-events: none;

        @media #{map-get($display-breakpoints, 'md-and-up')} {
            position: absolute;
            top: 0;
            right: -30px;
            height: 100%;
        }

        .v-btn {
            pointer-events: all;
            clip-path: inset(0 -5px 0 0);

            // this ensures the button background color matches the content background color when the search result is expanded
            .app-drawer:has(.v-window-item--active > .search-result) & {
                background-color: var(--search-result-bg-color);
            }
        }
    }

    &--full-height {
        @media #{map-get($display-breakpoints, 'sm-and-down')} {
            height: calc(100% - var(--v-layout-top)) !important;
        }
    }

    &:has(.search-result[expanded='true']) {
        .app-drawer__inner {
            height: 100%;
        }
    }
}
</style>
