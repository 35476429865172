<template>
    <k-input :disabled="disabled" class="datetime-selector">
        <v-btn-toggle v-model="__type" rounded="md" color="primary" variant="flat" group mandatory class="datetime-selector__btns">
            <v-btn v-for="dt in _types" :key="dt" :value="dt" class="datetime-selector__btn">
                {{ t(`common.resources.dateTimePicker.${camelCase(DateType[dt])}`, DateType[dt]) }}
            </v-btn>
        </v-btn-toggle>
        <div v-if="showDateTimePicker" class="mt-2 w-100">
            <date-time-picker
                v-model="_modelValue"
                :min-date="minDate"
                :max-date="maxDate"
                :disabled="disabled"
                density="compact"
                variant="outlined"
                color="primary"
                base-color="grey-lighten-2"
                bg-color="white"
                base-class="rounded"
                active-class="elevation-4"
                rounded="md"
            />
        </div>
    </k-input>
</template>

<script setup lang="ts">
import { DateType } from '@/features/common/models';
import { KInput, DateTimePicker } from '@geta/kolumbus-frontend/components';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { startOfMinute } from 'date-fns';
import { camelCase } from 'lodash-es';

interface Props {
    modelValue?: Date;
    type?: DateType;
    types?: DateType[];
    minDate?: Date | number;
    maxDate?: Date | number;
    disabled?: boolean;
}

const { t } = useI18n();
const props = withDefaults(defineProps<Props>(), { type: DateType.Now });
const _modelValue = defineModel<Date>('modelValue');
const _type = defineModel<DateType>('type', { default: DateType.Now });
const localType = ref<DateType>(_type.value);
const __type = computed({
    get() {
        return _type.value || localType.value;
    },
    set(value) {
        _type.value = localType.value = value;

        if (value === DateType.Now) {
            _modelValue.value = undefined;
        } else if (!_modelValue.value) {
            _modelValue.value = startOfMinute(new Date());
        }
    }
});
const _types = computed(() => props.types || [DateType.Now, DateType.DepartAt]);
const showDateTimePicker = computed(() => __type.value !== DateType.Now);
</script>

<style lang="scss">
@use '@geta/kolumbus-frontend/styles/vuetify' as *;
@use 'vuetify/lib/styles/tools' as *;

.datetime-selector {
    .v-field {
        --v-field-input-padding-bottom: 0;
    }

    &__btns {
        width: 100%;
        display: flex;
        flex-direction: row;

        &.v-btn-group--density-default.v-btn-group {
            height: 40px;
        }

        &.v-btn-group .v-btn:not(:last-child) {
            border-inline-end: 1px solid var(--v-color-grey-lighten-2);
        }

        &.v-btn-group .v-btn--active,
        &.v-btn-group .v-btn:has(+ .v-btn--active) {
            border-inline-end: none !important;
        }
    }

    &__btn {
        flex: 1 1 0;
        border: 1px solid var(--v-color-grey-lighten-2);
        font-size: calc(18px / $font-size-root) * 1rem;
        font-weight: 400;

        &.v-btn--active {
            font-weight: 700;
            border: 0 none;
        }
    }
}
</style>
