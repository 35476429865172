import { nextTick, watch, WatchHandle } from 'vue';
import { useBookingStore } from '../stores';
import { useMainStore } from '@/features/common/stores';
import { useTripSearchStore } from '@/features/trips/stores';
import { useDeparturesSearchStore } from '@/features/departures/stores';
import { isEmpty } from 'lodash-es';
import { isAfter, isDate, isSameDay } from 'date-fns';
import { BookingStatus, DepartureDto, LegDto, NotificationMessageType } from '@/types/webapi';
import { useBookingMessages } from './useBookingMessages';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@geta/kolumbus-frontend/features/authentication';

export function useBookingFeature() {
    const mainStore = useMainStore();
    const userStore = useUserStore();
    const bookingStore = useBookingStore();
    const tripSearchStore = useTripSearchStore();
    const departureSearchStore = useDeparturesSearchStore();
    const { stopSignalOrder, maxBookingExceeded, bookings, selectedBooking } = storeToRefs(bookingStore);
    const { selectedDeparture, selectedDepartureIdIsExpanded } = storeToRefs(departureSearchStore);
    const { selectedTripPattern, selectedTripPatternIdIsExpanded } = storeToRefs(tripSearchStore);
    const { generateBookingExistsDescriptionText } = useBookingMessages();

    function updateNotificationMessage(legOrDeparture: LegDto | DepartureDto) {
        const bookingNotificationMessage = legOrDeparture.notificationMessages?.find(
            x => x.type === NotificationMessageType.OnlineBookingArrangement
        );

        if (!bookingNotificationMessage) return;

        const leg = legOrDeparture as LegDto;
        const departure = legOrDeparture as DepartureDto;
        const fromQuay = leg.from?.quay?.name || departure.quay?.name;
        const transportMode = leg.transitInfo?.transportMode || departure.transportMode;

        // store original message
        (bookingNotificationMessage as any).originalText = bookingNotificationMessage.text;

        bookingNotificationMessage.text = generateBookingExistsDescriptionText({ fromQuay, transportMode });
        bookingNotificationMessage.type = NotificationMessageType.BookingConfirmation;
    }

    function restoreNotificationMessage(legOrDeparture: LegDto | DepartureDto) {
        const notificationMessage = legOrDeparture.notificationMessages?.find(x => x.type === NotificationMessageType.BookingConfirmation);
        if (!notificationMessage || !('originalText' in notificationMessage)) return;

        notificationMessage.text = notificationMessage.originalText as string;
        notificationMessage.type = NotificationMessageType.OnlineBookingArrangement;

        delete notificationMessage.originalText;
    }

    async function loadBookingData(legOrDeparture: LegDto | DepartureDto) {
        if (!legOrDeparture.bookingMetadata) return;

        if (userStore.isLoggedIn) {
            // load user bookings
            await bookingStore.loadBookings();
        }

        await nextTick();

        // check if user has valid booking, and update notification messages as necessary
        if (bookings.value.length > 0) {
            const { activeDate, serviceJourneyRef, stopSequence } = legOrDeparture.bookingMetadata;
            // set selectedBooking to the booking that matches the activeDate + serviceJourneyRef + stopSequence
            selectedBooking.value = bookings.value.find(
                x =>
                    isDate(activeDate) &&
                    isSameDay(x.activeDate, activeDate) &&
                    x.serviceJourneyRef === serviceJourneyRef &&
                    x.stopSequence === stopSequence
            );
            // update notification message if selectedBooking exists
            if (selectedBooking.value) {
                return updateNotificationMessage(legOrDeparture);
            }
        }

        // reset notification message
        restoreNotificationMessage(legOrDeparture);

        // if booking limit date time is in the future do nothing
        const { bookingLimitDateTime } = legOrDeparture.bookingMetadata;
        if (!isDate(bookingLimitDateTime) || isAfter(bookingLimitDateTime, mainStore.currentTime)) return;

        await bookingStore.loadStopSignalOrder();
        await nextTick();

        // update notification message if stopSignalOrder exists
        if (!stopSignalOrder.value) return;

        updateNotificationMessage(legOrDeparture);
    }

    function reset() {
        nextTick(() => {
            selectedBooking.value = undefined;
            stopSignalOrder.value = undefined;
            maxBookingExceeded.value = false;
        });
    }

    let tripPatternWatcher: WatchHandle | undefined;
    let departureWatcher: WatchHandle | undefined;
    let expandedWatcher: WatchHandle | undefined;
    let bookingStatusWatcher: WatchHandle | undefined;

    function startWatchers() {
        tripPatternWatcher = watch(
            selectedTripPattern,
            async (value, oldValue) => {
                if (isEmpty(value) || value.id === oldValue?.id) return;

                reset();

                const bookingLeg = value.legs?.find(x => x.bookingMetadata?.onlineBookingIsAvailable);
                if (!bookingLeg) return;

                await loadBookingData(bookingLeg);
            },
            { immediate: true }
        );

        departureWatcher = watch(
            selectedDeparture,
            async (value, oldValue) => {
                if (isEmpty(value?.bookingMetadata) || value.isCancelled || value.id === oldValue?.id) return;

                reset();

                await loadBookingData(value);
            },
            { immediate: true }
        );

        // reset booking state when selectedDepartureIdIsExpanded or selectedTripPatternIdIsExpanded is false when booking is cancelled
        expandedWatcher = watch(
            [selectedDepartureIdIsExpanded, selectedTripPatternIdIsExpanded],
            ([departureIsExpanded, tripPatternIsExpanded]) => {
                if (departureIsExpanded || tripPatternIsExpanded || selectedBooking.value?.status !== BookingStatus.Cancelled) return;

                reset();
            }
        );

        bookingStatusWatcher = watch(
            () => selectedBooking.value?.status,
            async status => {
                if (tripSearchStore.selectedTripPattern) {
                    const bookingLeg = tripSearchStore.selectedTripPattern.legs?.find(x => x.bookingMetadata?.onlineBookingIsAvailable);
                    if (bookingLeg) {
                        if (!status || status === BookingStatus.Cancelled) {
                            // reset notification message for selected trip pattern leg if booking is cancelled
                            restoreNotificationMessage(bookingLeg);
                        } else {
                            // else update notification message for selected trip pattern leg
                            updateNotificationMessage(bookingLeg);
                        }
                    }
                } else if (departureSearchStore.selectedDeparture) {
                    if (!status || status === BookingStatus.Cancelled) {
                        // reset notification message for selected departure if booking is cancelled
                        restoreNotificationMessage(departureSearchStore.selectedDeparture);
                    } else {
                        // else update notification message for selected departure
                        updateNotificationMessage(departureSearchStore.selectedDeparture);
                    }
                }
            }
        );
    }

    function stopWatchers() {
        tripPatternWatcher?.();
        departureWatcher?.();
        expandedWatcher?.();
        bookingStatusWatcher?.();
    }

    watch(
        () => mainStore.config.bookingConfig?.enabled,
        value => {
            if (value) {
                startWatchers();
            } else {
                stopWatchers();
            }
        },
        {
            immediate: true
        }
    );
}
