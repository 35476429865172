import { useGeolocationHelper } from '@/features/common/composables';
import { useMapStore } from '@/features/map/stores';
import { storeToRefs } from 'pinia';
import { useMainStore } from '@/features/common/stores';
import { computed } from 'vue';
import { PointTuple } from 'leaflet';
import { useDisplay } from 'vuetify';

export const defaultPadding = 25;

export function useMapUnderlay() {
    const { mobile: isMobile } = useDisplay();
    const { showAppDrawer } = storeToRefs(useMainStore());
    const mapStore = useMapStore();
    const { userLocation, paddingTopLeft, paddingBottomRight: _paddingBottomRight, ...storeRefs } = storeToRefs(mapStore);
    const { isSupported, getCurrentPosition } = useGeolocationHelper();

    let abortController: AbortController;
    async function locateUser() {
        // abort any existing requests
        abortController?.abort();

        try {
            // reset abortController
            abortController = new AbortController();
            // request for geolocation
            const position = await getCurrentPosition(abortController);
            // set if geolocation is valid
            if (position) {
                userLocation.value = position.coords;
            }
        } catch (e) {
            userLocation.value = null;

            throw e;
        }
    }

    // set bottom padding based on drawer height in mobile mode - this is set by relevant pages
    const paddingBottomRight = computed(() => (showAppDrawer.value && isMobile.value ? _paddingBottomRight.value : undefined));

    return {
        ...storeRefs,
        padding: [defaultPadding, defaultPadding] as PointTuple,
        paddingTopLeft,
        paddingBottomRight,
        userLocation,
        canUseGeolocation: isSupported,
        locateUser
    };
}
