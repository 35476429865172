import { BookingContactDto, BookingDto, BookingMethod, TransportMode } from '@/types/webapi';
import { toLocalDate, toLocaleTimeString } from '@geta/kolumbus-frontend/composables';
import { addDays, format, formatRelative, isAfter, isDate, isSameDay } from 'date-fns';
import { useI18n } from 'vue-i18n';
import { enUS as _enUS, nb as _nb } from 'date-fns/locale';

export interface GenerateDescriptionTextParams {
    fromQuay?: string;
    bookingLimitDateTime?: Date;
    bookingMethods?: BookingMethod[];
    bookingContact?: BookingContactDto;
    transportMode?: TransportMode;
    now?: Date;
}

export function useBookingMessages() {
    const { t, locale } = useI18n();

    // override formatRelative function
    const formatRelativeLocaleEnUS: { [key: string]: string } = {
        lastWeek: "'last' eeee 'at' HH:mm",
        yesterday: "'yesterday at' HH:mm",
        today: "'today at' HH:mm",
        tomorrow: "'tomorrow at' HH:mm",
        nextWeek: "MMMM do 'at' HH:mm",
        other: 'P'
    };
    const formatRelativeLocaleNb: { [key: string]: string } = {
        lastWeek: "p 'forrige' eeee",
        yesterday: "p 'i går'",
        today: "p 'i dag'",
        tomorrow: "p 'i morgen'",
        nextWeek: "do MMMM 'kl.' p",
        other: 'P'
    };

    const enUS = {
        ..._enUS,
        formatRelative: (token: string): string => formatRelativeLocaleEnUS[token]
    };
    const nb = {
        ..._nb,
        formatRelative: (token: string): string => formatRelativeLocaleNb[token]
    };

    const options = { locale: locale.value == 'no' ? nb : enUS };

    function generateDescriptionText(params: GenerateDescriptionTextParams) {
        if (!params) return '';

        const { fromQuay, bookingLimitDateTime, bookingMethods = [], bookingContact, now = new Date() } = params;

        let result: string[] = [];
        if (fromQuay && isDate(bookingLimitDateTime)) {
            let bookingLimitDateTimeString: string;

            if (isSameDay(toLocalDate(bookingLimitDateTime), now)) {
                // today
                bookingLimitDateTimeString = t('common.resources.bookingArrangements.beforeTimeToday', [
                    toLocaleTimeString(bookingLimitDateTime, locale.value)
                ]);
            } else if (isSameDay(toLocalDate(bookingLimitDateTime), addDays(now, 1))) {
                // tomorrow
                bookingLimitDateTimeString = t('common.resources.bookingArrangements.beforeTimeTomorrow', [
                    toLocaleTimeString(bookingLimitDateTime, locale.value)
                ]);
            } else {
                // other dates
                bookingLimitDateTimeString = t('common.resources.bookingArrangements.beforeTimeOtherDay', [
                    format(toLocalDate(bookingLimitDateTime), t('booking.resources.dateTimeFormats.weekday'), options),
                    toLocaleTimeString(bookingLimitDateTime, locale.value)
                ]);
            }

            result.push(
                t('booking.resources.descriptionTexts.bookingOriginAndDeadline', {
                    fromQuay,
                    bookingLimitDateTime: bookingLimitDateTimeString
                })
            );
        }

        if (
            (bookingMethods.includes(BookingMethod.CallDriver) || bookingMethods.includes(BookingMethod.CallOffice)) &&
            bookingContact?.phone
        ) {
            result.push(t('booking.resources.descriptionTexts.bookingMethodTel', { contactPhone: bookingContact.phone }));
        }

        return result.join(' ');
    }

    function generateDeadlineExpiredDescriptionText(params: GenerateDescriptionTextParams) {
        if (!params) return '';

        const { fromQuay, bookingLimitDateTime, bookingMethods = [], bookingContact } = params;
        let result: string[] = [];

        if (fromQuay && isDate(bookingLimitDateTime)) {
            let bookingLimitDateTimeAndOrBookingMethod = t('booking.resources.descriptionTexts.bookingLimitTime', [
                toLocaleTimeString(bookingLimitDateTime, locale.value)
            ]);

            if (
                (bookingMethods.includes(BookingMethod.CallDriver) || bookingMethods.includes(BookingMethod.CallOffice)) &&
                bookingContact?.phone
            ) {
                bookingLimitDateTimeAndOrBookingMethod += t('booking.resources.descriptionTexts.bookingDeadlineMethodTel', {
                    contactPhone: bookingContact.phone
                });
            }

            result.push(
                t('booking.resources.descriptionTexts.bookingDeadlineOriginAndDeadline', {
                    fromQuay,
                    bookingLimitDateTimeAndOrBookingMethod
                })
            );
        }

        result.push(`<span class="text-error">${t('booking.resources.descriptionTexts.bookingDeadlineExpired')}</span>`);

        return result.join(' ');
    }

    function generateBookingExistsDescriptionText(params: GenerateDescriptionTextParams) {
        if (!params) return '';

        const { fromQuay, transportMode } = params;
        let result: string[] = [];

        if (fromQuay && transportMode) {
            const transportModeString = getTransportMode(transportMode);

            result.push(
                t('booking.resources.descriptionTexts.bookingAlreadyMadeByOther', { fromQuay, transportMode: transportModeString })
            );
        }

        return result.join(' ');
    }

    function generateCancelledBookingDescriptionText(booking: BookingDto, now?: Date) {
        if (!booking) return '';

        const { pickupQuayName: quayName, pickupTime: _pickupTime } = booking;
        const localPickupTime = toLocalDate(_pickupTime);

        const pickupTime =
            isDate(now) && isAfter(_pickupTime, now)
                ? formatRelative(localPickupTime, now, options)
                : format(localPickupTime, t('booking.resources.dateTimeFormats.weekdayAndTime'), options);

        return t('booking.resources.bookingCancelledDescription', { quayName, pickupTime });
    }

    function getTransportMode(transportMode: TransportMode) {
        switch (transportMode) {
            case TransportMode.Bus:
                return t('booking.resources.descriptionTexts.bookingTransportModeBus');
            case TransportMode.Water:
                return t('booking.resources.descriptionTexts.bookingTransportModeWater');
            default:
                return '';
        }
    }

    return {
        generateDescriptionText,
        generateDeadlineExpiredDescriptionText,
        generateBookingExistsDescriptionText,
        generateCancelledBookingDescriptionText
    };
}
